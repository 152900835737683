import type { VirtualItem } from '@tanstack/react-virtual'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { useLayoutEffect, useRef } from 'react'

interface UseVirtualizerProps {
  count: number
  estimatedSize: number
  paddingEnd?: number
}

interface UseVirtualizerReturn {
  items: VirtualItem[]
  measureElement: (node: Element | null) => void
  parentRef: React.RefObject<HTMLDivElement>
  totalSize: number
  translateY: number
}

const useVirtualizer = ({
  count,
  estimatedSize,
  paddingEnd,
}: UseVirtualizerProps): UseVirtualizerReturn => {
  const parentRef = useRef<HTMLDivElement>(null)
  const parentOffsetRef = useRef(0)

  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0
  }, [])

  const virtualizer = useWindowVirtualizer({
    count,
    estimateSize: () => estimatedSize,
    scrollMargin: parentOffsetRef.current,
    overscan: 10,
    paddingEnd,
  })
  const items = virtualizer.getVirtualItems()
  const start = items[0]?.start ?? virtualizer.options.scrollMargin

  return {
    items,
    measureElement: virtualizer.measureElement,
    parentRef,
    totalSize: virtualizer.getTotalSize(),
    translateY: start - virtualizer.options.scrollMargin,
  }
}

export default useVirtualizer
