import type { ReactNode } from 'react'
import React from 'react'

import type { Size } from '../../../themes/types'
import { StyledStack } from './Stack.styles'

export interface StackProps {
  'data-e2e'?: string
  alignment?: 'stretch' | 'flex-start' | 'center' | 'flex-end'
  /** @deprecated use alignment prop instead */
  center?: boolean
  children?: ReactNode
  className?: string
  fullWidth?: boolean
  inline?: boolean
  spacing?: Size
}

const Stack = ({
  'data-e2e': dataE2e,
  alignment,
  center = false,
  children,
  className,
  fullWidth = false,
  inline = false,
  spacing,
}: StackProps) => (
  <StyledStack
    alignment={alignment}
    center={center}
    className={className}
    data-e2e={dataE2e}
    fullWidth={fullWidth}
    inline={inline}
    spacing={spacing}
  >
    {children}
  </StyledStack>
)

export default Stack
