import type { ReactNode } from 'react'
import React from 'react'

import type { Size } from '../../../themes/types'
import { StyledBox } from './Box.styles'

export interface BoxProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  inset?: Size
}

const Box = ({ 'data-e2e': dataE2e, inset, children, className }: BoxProps) => (
  <StyledBox inset={inset} className={className} data-e2e={dataE2e}>
    {children}
  </StyledBox>
)

export default Box
